import { Options } from 'highcharts';

export const splineChartOptions: Options = {
    chart: {
        type: 'spline',
        inverted: false,
        height: 300,
        options3d: {
            enabled: true,
            alpha: 15,
            beta: 15,
            
         }
    },
    title: {
        text: ''
    },
    credits: {
        enabled: false
    },
    legend: {
        enabled: true
    }
}