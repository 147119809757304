import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { pieChartOptions } from 'src/app/shared/helpers/pieChartOptions';
import { splineChartOptions } from 'src/app/shared/helpers/splineChartOptions';
import { ChartsService } from '../charts.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class PeopleComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  campaignOne!: FormGroup;
  pieChart: any;
  splineChart: any;
  filterTitle: any[] = [];
  filterShow: boolean = false;
  chartFilters: any[] = []

  allMetricsChartData: any[] = [];
  allMetricsDropdown: any[] = [];
  allMetricPieCharts: Chart[] = [];
  allMetricSplineCharts: Chart[] = [];
  allMetricChartFilters: any[] = [];
  allFilterShow: boolean[] = [];

  chartData: any;
  queryParams: any;
  ColorRandom: any;
  arrow: boolean[] = new Array().fill(false);

  constructor(private router: Router, private peopleService: ChartsService,
    private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 13)),
      end: new FormControl(new Date(year, month, 16)),
    });

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;
      let serviceParameter = new URLSearchParams(params).toString();
      this.spinner.show();
      this.getQuadrantMetrics(serviceParameter).then((res: any) => {
        this.constructCharts();
        this.spinner.hide();
      }).catch((err:any)=>{this.spinner.hide();});
    });
  }

  ngOnInit(): void {
    // Highcharts.setOptions({
    //   colors: ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"]
    // });
  }

  navigateTo(link: string) {
    this.router.navigate([link], { queryParams: this.queryParams })
  }
  getQuadrantMetrics(queryParams: any) {
    return new Promise<void>((resolve, reject) => {
      this.peopleService.getPeopleMetrics(queryParams).subscribe((metricData: any) => {
        let flag=true;
        for (var indivMetricIndex = 0; indivMetricIndex < Object.keys(metricData).length; indivMetricIndex++) {
          this.allMetricChartFilters[indivMetricIndex] = Object.keys(metricData[Object.keys(metricData)[indivMetricIndex]])
          this.allMetricsChartData[indivMetricIndex] = metricData[Object.keys(metricData)[indivMetricIndex]];
          this.allMetricsChartData[indivMetricIndex]["name"] = Object.keys(metricData)[indivMetricIndex];
          this.allFilterShow[indivMetricIndex] = false;
          if ((Object.keys(metricData).length) - 1 == indivMetricIndex) {
            flag=false;
            resolve();
          }
        }
        if(flag){
          reject();
        }
      })

    });
  }

  constructCharts() {

    for (var i = 0; i < this.allMetricsChartData.length; i++) {

      this.dataToPieChart('', Object.keys(this.allMetricsChartData[i])[0], i);
      this.dataToLineChart('', Object.keys(this.allMetricsChartData[i])[0], i);
    }

  }

  filterChart(checkedValue: any, chartIndex: any) {
    this.dataToPieChart("", checkedValue, chartIndex);
    this.dataToLineChart("", checkedValue, chartIndex);
  }

  dataToPieChart(selected: any = '', filterValue: any = 'over_all', chartIndex: number = 0) {
    this.filterTitle[chartIndex] = filterValue;
    // let total_resource, no_of_resource;
    let seriesData: any;
    if (filterValue == 'Over All') {
      if (selected == '') {
        // total_resource = this.allMetricsChartData[chartIndex][filterValue].chart_value.pie_chart.map((item: any) => item.total_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
        // no_of_resource = this.allMetricsChartData[chartIndex][filterValue].chart_value.pie_chart.map((item: any) => item.no_of_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
        this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex][filterValue].pie_chart).map(i => this.allMetricsChartData[chartIndex][filterValue].pie_chart[i]['name']);
        seriesData = this.allMetricsChartData[chartIndex][filterValue].pie_chart;
      }
      else {
        this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex][filterValue].pie_chart).map(i => this.allMetricsChartData[chartIndex][filterValue].pie_chart[i]['name'])
        seriesData = this.allMetricsChartData[chartIndex][filterValue].pie_chart.filter((item: any) => item.name.includes(selected.target.value))
        // total_resource = this.allMetricsChartData[chartIndex][filterValue].chart_value.pie_chart.filter((item: any) => item.name.includes(selected.target.value)).map((item: any) => item.total_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
        // no_of_resource = this.allMetricsChartData[chartIndex][filterValue].chart_value.pie_chart.filter((item: any) => item.name.includes(selected.target.value)).map((item: any) => item.no_of_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0); 
      }

    }
    else {
      if (selected == '') {
        if (this.allMetricsChartData[chartIndex][filterValue].pie_chart) {
          this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex][filterValue].pie_chart).map(i => this.allMetricsChartData[chartIndex][filterValue].pie_chart[i]['name'])
          seriesData = [this.allMetricsChartData[chartIndex][filterValue].pie_chart[0]]; //should be changed to ALL dropdown
          //  if(this.allMetricsChartData[chartIndex][filterValue].pie_chart.filter((item: any) => item.name.includes("All")).length > 0) {
          //   seriesData =this.allMetricsChartData[chartIndex][filterValue].pie_chart.filter((item: any) => item.name.includes("All"));
          //  }
          //  else {
          //   seriesData = [this.allMetricsChartData[chartIndex][filterValue].pie_chart[0]]; //should be changed to ALL dropdown
          //  }
          // total_resource = this.allMetricsChartData[chartIndex][filterValue].pie_chart.map((item: any) => item.total_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
          // no_of_resource = this.allMetricsChartData[chartIndex][filterValue].pie_chart.map((item: any) => item.no_of_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
        }
        else {
          this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex][filterValue])
          seriesData = this.allMetricsChartData[chartIndex][filterValue][this.allMetricsDropdown[chartIndex][0]].pie_chart;
          // total_resource = this.allMetricsChartData[chartIndex][filterValue][this.allMetricsDropdown[chartIndex][0]].pie_chart.map((item: any) => item.no_of_Delay).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0); //this.allMetricsDropdown[chartIndex][0] should be change to all once it's implemented
          // no_of_resource = this.allMetricsChartData[chartIndex][filterValue][this.allMetricsDropdown[chartIndex][0]].pie_chart.map((item: any) => item["no_of_On Time"]).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
        }
      }
      else {
        if (this.allMetricsChartData[chartIndex][filterValue].pie_chart) {
          // total_resource = this.allMetricsChartData[chartIndex][filterValue].pie_chart.filter((item: any) => item.name.includes(selected.target.value)).map((item: any) => item.total_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
          // no_of_resource = this.allMetricsChartData[chartIndex][filterValue].pie_chart.filter((item: any) => item.name.includes(selected.target.value)).map((item: any) => item.no_of_resource).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
          this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex][filterValue].pie_chart).map(i => this.allMetricsChartData[chartIndex][filterValue].pie_chart[i]['name'])
          seriesData = this.allMetricsChartData[chartIndex][filterValue].pie_chart.filter((item: any) => item.name.includes(selected.target.value));

        }
        else {
          this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex][filterValue])
          seriesData = this.allMetricsChartData[chartIndex][filterValue][selected.target.value].pie_chart;
          // total_resource = this.allMetricsChartData[chartIndex][filterValue][selected.target.value].pie_chart.map((item: any) => item.no_of_Delay).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
          // no_of_resource = this.allMetricsChartData[chartIndex][filterValue][selected.target.value].pie_chart.map((item: any) => item["no_of_On Time"]).reduce((prev: any, curr: any) => parseInt(prev) + parseInt(curr), 0);
        }

      }
    }
    // let seriesData: any =
    //   [{
    //     type: 'pie',
    //     name: '',
    //     data: [['Total Resources', parseInt(total_resource)], ['No of Resources', parseInt(no_of_resource)]]
    //   }]
    pieChartOptions.colors = ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"];
    this.allMetricPieCharts[chartIndex] = new Chart(pieChartOptions)
    for (var i = 0; i <= seriesData.length; i++) {
      this.allMetricPieCharts[chartIndex].addSeries(seriesData[i], true, true)
    }
  }

  dataToLineChart(selected: any = '', filterValue: any = 'over_all', chartIndex: number = 0) {
    this.filterTitle[chartIndex] = filterValue;
    let seriesData: any = [];
    if (filterValue == 'Over All') {
      if (selected == '') {
        seriesData = this.allMetricsChartData[chartIndex][filterValue].line_chart;
      }
      else {
        seriesData = this.allMetricsChartData[chartIndex][filterValue].line_chart.filter((item: any) => item.name.includes(selected.target.value))
      }
    }
    else {
      if (selected == '') {
        if (this.allMetricsChartData[chartIndex][filterValue].line_chart) {
          seriesData = this.allMetricsChartData[chartIndex][filterValue].line_chart;
        }
        else {
          seriesData = this.allMetricsChartData[chartIndex][filterValue][this.allMetricsDropdown[chartIndex][0]].line_chart;//this.allMetricsDropdown[chartIndex][0] should be change to all once it's implemented
        }

      }
      else {
        if (this.allMetricsChartData[chartIndex][filterValue].line_chart) {
          seriesData = this.allMetricsChartData[chartIndex][filterValue].line_chart.filter((item: any) => item.name.includes(selected.target.value));
        }
        else {
          seriesData = this.allMetricsChartData[chartIndex][filterValue][selected.target.value].line_chart;
        }

      }
    }
    splineChartOptions.yAxis = {
      title: {
        text: ""
      }
    }
    splineChartOptions.xAxis = {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    },
    splineChartOptions.colors = ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"];
      this.allMetricSplineCharts[chartIndex] = new Chart(splineChartOptions)
    for (var i = 0; i <= seriesData.length; i++) {
      this.allMetricSplineCharts[chartIndex].addSeries(seriesData[i], true, true)
    }
  }
  // getRandomcolors(index?: any) {
  //   const colors: any[] = ["#E1F2FF", "#E0FFF9", "#DFE0FF", "#FFEFE3", "#F9E4FF"];
  //   const Textcolors: any[] = ["#1A85D6", "#07B797", "#232459", "#FF6F00", "#B507E1"];
  //   this.ColorRandom = {
  //     bgColor: colors[index],
  //     textColor: Textcolors[index],
  //   };
  //   return this.ColorRandom;
  // }
}
