import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
  private deliveryOverallApi = "balance-score-card-menu/balance-score-card-charts-v2-only-delivery/?";
  private customerOverallApi = "balance-score-card-menu/balance-score-card-customer-overall-chart/?";
  private financeOverallApi = "balance-score-card-menu/balance-score-card-finance-overall-chart/?";
  private peopleOverallApi = "balance-score-card-menu/balance-score-card-people-overall-chart/?";

  constructor(private http: HttpClient) { }

  getRightPane(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    return this.http.get(`balance-score-card-menu/balance-score-card-right-pane/?${finalParams}`);
  }
  getRightPaneHeatMapData() {
    return this.http.get(`balance-score-card-menu/balance-score-card-right-pane/`);
  }

  getscoreCardQuadrant() {
    return this.http.get('metrics/balance-score-card/');
  }

  getMetricData(projectId: number, metricId: number) {
    return this.http.get(`balance-score-card-menu/balance-score-card-charts-v2/?id=${projectId}&metric_id=${metricId}`)
  }

  getDeliveryMetricData(projectId: number, quadrantId: number) {
    return this.http.get(`balance-score-card-menu/balance-score-card-charts-v2-only-delivery/?id=${projectId}&quadrant_id=${quadrantId}&limit=10`)
  }

  getQuadrantMetricList(projectId: number, balanceScorecardId: number) {
    return this.http.get(`delivery/project-metrics-list/?id=${projectId}&balance_score_card_id=${balanceScorecardId}`)
  }

  getOverallDashboard(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    let apiArray: any = [this.http.get(`${this.customerOverallApi}${finalParams}&flag=overall&year=2021`),
    this.http.get(`${this.financeOverallApi}${finalParams}&flag=overall&year=2021`),
    this.http.get(`${this.deliveryOverallApi}${finalParams}&flag=overall&year=2021`),
    this.http.get(`${this.peopleOverallApi}${finalParams}&flag=overall&year=2021`)
    ];
    // let apiArray: any = [this.http.get(`${this.customerOverallApi}${queryParams}&flag=overall&year=2021`),
    // this.http.get(`${this.financeOverallApi}${queryParams}&flag=overall&year=2021`),
    // this.http.get(`${this.deliveryOverallApi}${queryParams}&flag=overall&year=2021`),
    // this.http.get(`${this.peopleOverallApi}${queryParams}&flag=overall&year=2021`)
    // ];
    return forkJoin(apiArray);
  }

  getPeopleMetrics(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    return this.http.get(`${this.peopleOverallApi}year=2021&${finalParams}`);
  }

  getDeliveryMetrics(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    return this.http.get(`${this.deliveryOverallApi}year=2021&${finalParams}`);
  }

  getCustomerMetrics(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    return this.http.get(`${this.customerOverallApi}${queryParams}`);
  }

  getFinanceMetrics(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    return this.http.get(`${this.financeOverallApi}year=2021&${queryParams}`);
  }

  //Duplicate of shared chart service
  getPortfolioList(query?: any) {
    return this.http.get(`dynamic-form/?form_name=${query}`);
  }

  projectList() {
    return this.http.get('delivery/project-list/?flag=mapped_list');
  }

  getProgram() {
    return this.http.get(`dynamic-form/?form_name=opportunity_setup`)
  }

  getVendorList() {
    return this.http.get('dynamic-form/?form_name=opportunity_empanelled_vendors');
  }

  getPeopleOverallMetrics(queryParams: any) {
    let finalParams = this.getFormattedParams(queryParams);
    return this.http.get(`${this.peopleOverallApi}year=2021&flag=overall&${queryParams}`);
  }
  getFormattedParams(queryParams:any){        
    let params = queryParams?.split("&");
    let qparams:any={};
    params?.forEach((e:any)=>{
      let keyvalue = e?.split("=");
      if(keyvalue?.[0].includes('sub_portfolio')){
        qparams['portfolio'] = keyvalue[1];
      }else
        qparams[keyvalue?.[0]] = keyvalue[1]; 
    })
    const searchParams = new URLSearchParams();
    Object.keys(qparams).forEach(key => searchParams.append(key, qparams[key]));
    let finalParams = searchParams.toString();
    return finalParams;
  }

}
