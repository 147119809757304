<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="bg-color p-3">
  <div>
    <app-filter-card [pagetype]="'balance-scorecard'"></app-filter-card>
  </div>
  <div class="px-0 py-3">
    <app-bread-crumb></app-bread-crumb>
  </div>

  <div class="container-fluid pr-0 pl-0">
    <div class="row">
      <div class="col-sm-2">
        <div class="single-left-pane">
          <app-side-menu></app-side-menu>
        </div>
      </div>
      <div class="col-sm-7">
        <div class="single-center-pane">
          <router-outlet></router-outlet>
        </div>
      </div>
      <div class="col-sm-3 p-0">
        <div class="single-right-pane">
          <div>
            <span class="bubble-top" style="background-color: #ed0000"
              >&nbsp;</span
            >
            <div class="d-flex align-items-center mb-3">
              <h6 class="mb-0">
                {{ staticText.balance_scorecard.dashboard.focus_zone }}
              </h6>
              <button
                *ngIf="!cards?.cardName"
                class="small-icon-button ml-auto"
                style="box-shadow: 0px 0px 15px gray"
                (click)="openWhatIfDialog()"
                matTooltip="what if"
                mat-icon-button
              >
                <img
                  class="mb-1"
                  src="assets/Icons/what (5).svg"
                  alt="what if"
                  height="30px"
                  width="30px"
                />
              </button>
            </div>
            <div>
              <app-individual-scorecard
                class="mt-2 mb-2"
              ></app-individual-scorecard>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingZero">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseZero"
                    aria-expanded="true"
                    aria-controls="collapseZero"
                    style="font-size: 13px"
                  >
                    {{ staticText.balance_scorecard.dashboard.okr_spread }}
                  </button>
                </h2>
                <div
                  id="collapseZero"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingZero"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-2">
                    <div
                      *ngFor="let okr of okrDetails"
                      [ngStyle]="{ 'background-color': okr?.color }"
                      class="impacted-okr-style"
                    >
                      <div>{{ okr?.display_name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    style="font-size: 13px"
                  >
                    {{ staticText.balance_scorecard.dashboard.risk_spread }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-2">
                    <div class="accordion" id="accordionPanel">
                      <div
                        *ngFor="let risk of riskDetails; let i = index"
                        class="accordion-item my-2"
                      >
                        <h2 class="accordion-header" id="heading{{ i }}">
                          <button
                            class="accordion-button {{
                              i === 0 ? '' : 'collapsed'
                            }}"
                            type="button"
                            data-bs-toggle="collapse"
                            attr.data-bs-target="#collapse{{ i }}"
                            aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                            aria-controls="collapse{{ i }}"
                            style="font-size: 13px; font-weight: 700"
                            [ngStyle]="{ background: risk.color }"
                          >
                            {{ risk.risk_name }}
                          </button>
                        </h2>
                        <div
                          id="collapse{{ i }}"
                          class="accordion-collapse collapse {{
                            i === 0 ? 'show' : ''
                          }}"
                          aria-labelledby="heading{{ i }}"
                          data-bs-parent="#accordionPanel"
                        >
                          <div class="accordion-body p-2">
                            <div class="flex-container-1">
                              <div
                                *ngIf="
                                  risk.portfolio && risk.portfolio?.length <= 0
                                "
                                class="trn-no"
                              >
                                No records found.
                              </div>
                              <app-loading
                                style="width: 100%; min-height: 200px"
                                *ngIf="!risk.portfolio"
                              ></app-loading>
                              <div
                                class="flex-item p-1"
                                *ngFor="let x of risk.portfolio"
                              >
                                <div
                                  class="trn-x"
                                  [ngStyle]="{ background: x.color }"
                                  (click)="openPortfolio(x)"
                                  class="portfolio-card"
                                >
                                  <p class="m-0">
                                    {{
                                      x.name + " (" + x.projects.length + ")"
                                    }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    style="font-size: 13px"
                  >
                    {{ staticText.balance_scorecard.dashboard.recent_releases }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-1">
                    <div
                      *ngFor="let release of recentReleases"
                      [ngStyle]="{ 'background-color': release?.color }"
                      class="impacted-okr-style"
                    >
                      <div>{{ release?.release_name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                    style="font-size: 13px"
                  >
                    {{
                      staticText.balance_scorecard.dashboard.upcoming_releases
                    }}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-1">
                    <div
                      *ngFor="let release of upcomingReleases"
                      [ngStyle]="{ 'background-color': release?.color }"
                      class="impacted-okr-style"
                    >
                      <div>{{ release?.release_name }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="accordion-item"
                *ngIf="keyActionAreas && keyActionAreas.length > 0"
              >
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                    style="font-size: 13px"
                  >
                    {{
                      staticText.balance_scorecard.dashboard.key_action_areas
                    }}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-0">
                    <div
                      class="project-cont"
                      style="background-color: #f8ae432b"
                    >
                      <ul style="list-style-type: none; padding-left: 10px">
                        <li
                          class="fs-16 mb-1"
                          style="color: #9b8566"
                          *ngFor="let action of keyActionAreas"
                          routerLink="/actiontracker"
                        >
                          {{ action?.title }}
                          <small class="fs-12 mb-2 d-block text-black">{{
                            action?.due_date | date : "dd-MM-YYYY"
                          }}</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                    style="font-size: 13px"
                  >
                    Curated from Web
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body p-2">
                    <app-gnews
                      [pagename]="'Balanced Scorecard'"
                      [display_header]="'false'"
                    ></app-gnews>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div>
                            <app-individual-scorecard class="mt-2 mb-2"></app-individual-scorecard>
                        </div> -->

            <!-- <div class="d-flex flex-column p-0">
                            <div class="p-0">
                                <h6 class="mb-0 ml-3" style="color:#232459">
                                    {{staticText.balance_scorecard.dashboard.focus_zone}}</h6>
                            </div>
                            <div class="card-body p-0">
                                <div class="d-flex flex-column">
                                    <div class="p-1">
                                        <div class="accordion" id="FocusZoneContainer">
                                            <div>
                                                <app-individual-scorecard class="mt-2 mb-2"></app-individual-scorecard>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
            <!-- <br> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
