<div class="container p-0">
    <div class="row">
        <div class="col-md-12 mb-3">
            <div class="card shadow border-radius-15">
                <div class="px-3 py-3 d-flex align-items-center justify-content-between">
                    <div>
                        <h6 class="d-flex align-items-end mb-0">
                            <mat-icon class="mr-2">filter_list</mat-icon> {{staticText?.balance_scorecard?.finance_title}}
                        </h6>
                    </div>

                    <div (click)="navigateTo('balancedscorecard')">
                        <h6 class="d-flex align-items-end back mb-0 cursor-pointer">
                            <span class="font-15"><i class="fa fa-chevron-left back pb-1" aria-hidden="true"></i>{{staticText?.common?.go_back}}</span>
                        </h6>
                    </div>
                </div>
                <!--Start of Accordion-->
                <div class="accordion accordion-flush mb-5" id="chartAccordion">
                    <div class="container-fluid pe-3 ps-3" *ngIf="allMetricsChartData.length !== 0">
                        <ng-container *ngFor="let financeMetrics of allMetricsChartData; let chartIndex=index">
                            <div class="d-flex justify-content-between align-items-center text-white accordion-item border-0 cursor-pointer mt-1"
                                style="border-radius: 5px;" [attr.data-bs-target]="'#chart'+chartIndex"
                                data-bs-toggle="collapse" aria-expanded="true"
                                [ngStyle]="{'backgroundColor': (chartIndex|getRandomColor:'chart').bgColor,'color': (chartIndex|getRandomColor:'chart').textColor}"
                                (click)="arrow[chartIndex]=!arrow[chartIndex];">
                                <div class="p-2">
                                    <span class="badge badgeTitle">
                                        <h5 class="mb-0 fs-16">{{financeMetrics?.name}}</h5>
                                    </span>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="treearrowgoal  p-2 d-flex align-items-center">
                                        <span class="material-icons-outlined" *ngIf="!arrow[chartIndex]">
                                            keyboard_arrow_down
                                        </span>
                                        <span class="material-icons-outlined" *ngIf="arrow[chartIndex]">
                                            keyboard_arrow_up
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-collapse collapse" [id]="'chart'+chartIndex"
                                [attr.data-bs-parent]="'chartAccordion'">
                                <div class="d-flex flex-column">
                                    <div class="d-flex flex-row justify-content-end align-items-center">


                                        <div class="p-2" style="position: relative;"
                                            *ngIf="allMetricChartFilters[chartIndex].length > 1">
                                            <span class="material-icons-outlined cursor-pointer filterIcon"
                                                (click)="allFilterShow[chartIndex] = !allFilterShow[chartIndex]">
                                                filter_alt
                                            </span>
                                            <ng-container *ngIf="allFilterShow[chartIndex]">
                                                <div class="checkFilter">
                                                    <div id="pointer"></div>
                                                    <div class="card border-radius-15 shadow chartFilter"
                                                        style="background-color: #FAF9FF;">
                                                        <span class="bubble-top"
                                                            style="background-color: #AB266B;">&nbsp;</span>
                                                        <div class="card-body">
                                                            <h5 class="card-title border-bottom fs-16">{{staticText?.balance_scorecard?.selections}}</h5>
                                                            <div class="p-1 d-flex flex-column filterSelections">
                                                                <div class="p-1">
                                                                    <!-- <mat-form-field appearance="outline">
                                                                <mat-date-range-input [formGroup]="campaignOne"
                                                                    [rangePicker]="campaignOnePicker"
                                                                    [comparisonStart]="campaignOne.value.start"
                                                                    [comparisonEnd]="campaignOne.value.end">
                                                                    <input matStartDate placeholder="Start date"
                                                                        formControlName="start">
                                                                    <input matEndDate placeholder="End date"
                                                                        formControlName="end">
                                                                </mat-date-range-input>
                                                                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker">
                                                                </mat-datepicker-toggle>
                                                                <mat-date-range-picker #campaignOnePicker>
                                                                </mat-date-range-picker>
                                                            </mat-form-field> -->
                                                                </div>
                                                                <div class="p-1"
                                                                    *ngFor="let filter of allMetricChartFilters[chartIndex]">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="radio"
                                                                            [checked]="filter !== filterTitle[chartIndex] ? false : true"
                                                                            [value]="filter" [id]="filter"
                                                                            data-toggle="switch"
                                                                            (click)="filterChart(filter, chartIndex)">
                                                                        <label class="form-check-label fs-14"
                                                                            [for]="filter">
                                                                            {{filter | titlecase}}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 p-2">
                                            <div class="card chartCard">
                                                <div class="card-body p-0">
                                                    <div class="d-flex flex-row justify-content-between  border-bottom p-2"
                                                        *ngIf="financeMetrics.name == 'Unit Resource Cost'">
                                                        <p class="text-capitalize mb-0 chartTitle">
                                                            {{filterTitle[chartIndex]}}</p>
                                                        <span class="material-icons-outlined chartInfo">
                                                            info
                                                        </span>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                        <!-- <div class="d-flex bd-highlight mb-0">
                                                    <div class="ms-auto p-2 bd-highlight">
                                                        <select class="form-control form-select form-select-sm inner-Filter"
                                                            *ngIf="allMetricsDropdown[chartIndex] && allMetricsDropdown[chartIndex].length > 1"
                                                            (change)="dataToPieChart($event, filterTitle[chartIndex], chartIndex)">
                                                            <option value="">All</option>
                                                            <option *ngFor="let subtype of allMetricsDropdown[chartIndex]"
                                                                [value]="subtype">
                                                                {{subtype}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="pie" [chart]="allMetricPieCharts[chartIndex]" ></div> -->
                                                        <ng-container
                                                            *ngIf="financeMetrics.name !== 'Unit Resource Cost' ">
                                                            <div class="d-flex bd-highlight mb-0">
                                                                <div class="ms-auto p-2 bd-highlight">
                                                                    <select
                                                                        class="form-control form-select form-select-sm inner-Filter"
                                                                        *ngIf="allMetricsDropdown[chartIndex] && allMetricsDropdown[chartIndex].length > 1"
                                                                        (change)="dataToLineChart($event, filterTitle[chartIndex], chartIndex)">
                                                                        <!-- <option value="">All</option> -->
                                                                        <option
                                                                            *ngFor="let subtype of allMetricsDropdown[chartIndex]"
                                                                            [value]="subtype">
                                                                            {{subtype}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="spline"
                                                                [chart]="allMetricSplineCharts[chartIndex]">
                                                            </div>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="financeMetrics.name == 'Unit Resource Cost'">
                                                            <div class="d-flex bd-highlight mb-0">
                                                                <div class="ms-auto p-2 bd-highlight">
                                                                    <select
                                                                        class="form-control form-select form-select-sm inner-Filter"
                                                                        *ngIf="allMetricsDropdown[chartIndex] && allMetricsDropdown[chartIndex].length > 1"
                                                                        (change)="dataToHistogram($event, filterTitle[chartIndex], chartIndex)">
                                                                        <!-- <option value="">All</option> -->
                                                                        <option
                                                                            *ngFor="let subtype of allMetricsDropdown[chartIndex]"
                                                                            [value]="subtype">
                                                                            {{subtype}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="spline"
                                                                [chart]="allMetricHistogramCharts[chartIndex]">
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!--end of accordion-->
                <div class="card-body" *ngIf="allMetricsChartData.length == 0">
                    <div class="row">
                        <div class="col-12 mx-auto">
                            <img src="./assets/images/no-record.png" class="no-records" height="100%"
                                width="100%">
                        </div>
                        <div class="col-8 mx-auto">
                            <p class="text-center fw-bold text-muted">
                                {{staticText.common.no_records_found}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>