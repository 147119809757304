import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'angular-highcharts'
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { ChartsService } from '../charts.service';
import {GetRandomColorPipe} from 'src/app/shared/pipes/get-random-color.pipe';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
  providers:[GetRandomColorPipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class DeliveryComponent implements OnInit {

  sparkLineData:any[] =[];
  unFormattedData:any[] = [];
  queryParams:any;
  staticText: any = (textConfiguration as any).default;
  constructor(private router: Router,private peopleService: ChartsService, private activatedRoute: ActivatedRoute,private spinner: NgxSpinnerService,private colorPipe:GetRandomColorPipe) {

    this.activatedRoute.queryParams.subscribe((params:any) => { 
      this.queryParams = params;
      let serviceParameter = new URLSearchParams(params).toString();
      this.spinner.show();
      this.getQuadrantMetrics(serviceParameter).then((res:any) => {
        this.constructCharts();
        this.spinner.hide();
      }).catch((err:any)=>{this.spinner.hide();});
    });

   }

  ngOnInit(): void {
    // this.getQuadrantMetrics().then((res:any) => {
    //   this.constructCharts();
    // });
  }

  navigateTo(link: string) {
    this.router.navigate([link], { queryParams: this.queryParams })
  }

  getQuadrantMetrics(queryParams:any) {
    return new Promise<void>((resolve, reject) => { 
    // this.peopleService.getDeliveryMetricData(306, 5).subscribe((quadrantMetrics:any) => {
    //   this.unFormattedData = [...quadrantMetrics];
    //   resolve();
    //  });
    this.peopleService.getDeliveryMetrics(queryParams).subscribe((quadrantMetrics:any) => {
        this.unFormattedData = [...quadrantMetrics];
        resolve();
       },err=> reject())
    });
  }

  constructCharts() { 
    this.unFormattedData.forEach((el:any) => { 
      el['areaChart'] = this.returnSparkLineChart(el.line_chart_value.data, 'line', el.line_chart_value.categories);
      el['columnChart'] = this.returnSparkLineChart(el.quarter_chart_value.data, 'column',el.quarter_chart_value.categories);
      this.sparkLineData.push(el);
    });
  }

  returnSparkLineChart(seriesData: any, type:any, categories:any) {
    return new Chart(<any>{
      title: {
        text: ''
      },
      credits: false,
      chart: {
        renderTo: 'container',
        width: 120,
        height: 20,
        type: type,
        margin: [2, 0, 2, 0],
        style: {
          overflow: 'visible'
        },
        skipClone: true
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      xAxis: {
        categories: categories,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
        }
      },
      tooltip: {
        enabled: true,
        outside: true
      },
      series: [{
        data: seriesData,
        // color: this.colorPipe.transform('','random'),
      }]
    });
  }


}
