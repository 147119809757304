import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartsService } from '../charts.service';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { splineChartOptions } from 'src/app/shared/helpers/splineChartOptions';
import { pieChartOptions } from 'src/app/shared/helpers/pieChartOptions';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewEncapsulation } from '@angular/core';
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class CustomerComponent implements OnInit {

  allMetricsDropdown: any[] = [];
  filterTitle: any[] = [];
  allMetricsChartData: any[] = [];
  allMetricChartFilters: any[] = [];
  allFilterShow: boolean[] = [];
  allMetricSplineCharts: Chart[] = [];

  allMetricPieCharts: Chart[] = [];
  queryParams: any;
  arrow: boolean[] = new Array().fill(false);

  staticText: any = (textConfiguration as any).default;
  constructor(private router: Router, private peopleService: ChartsService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;
      let serviceParameter = new URLSearchParams(params).toString();
      this.spinner.show();
      this.getQuadrantMetrics(serviceParameter).then((res: any) => {
        this.constructCharts();
        this.spinner.hide(); 
      }).catch((error):any=>{
        this.spinner.hide();        
      });
    });
  }

  ngOnInit(): void {
    // this.getQuadrantMetrics().then((res:any) => {
    //   this.constructCharts();
    // });
    // Highcharts.setOptions({
    //   colors: ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"]
    // });
  }

  getQuadrantMetrics(queryParams: any) {
    return new Promise<void>((resolve, reject) => {
      // this.peopleService.getQuadrantMetricList(306, 1).subscribe((quadrantMetrics:any) => { 
      //   console.log(quadrantMetrics);
      //   let allMetricApis:any = [];
      //   for(var metricIndex=0; metricIndex< quadrantMetrics.length; metricIndex++) {
      //     allMetricApis.push(this.peopleService.getMetricData(306, quadrantMetrics[metricIndex].id))
      //   }
      //   forkJoin(allMetricApis).subscribe((metricData:any) => {  
      //     for(var indivMetricIndex =0; indivMetricIndex < metricData.length; indivMetricIndex++) {
      //       this.allMetricChartFilters[indivMetricIndex] = Object.keys(metricData[indivMetricIndex])
      //       this.allMetricsChartData[indivMetricIndex] = metricData[indivMetricIndex];
      //       this.allMetricsChartData[indivMetricIndex]["name"] = quadrantMetrics[indivMetricIndex].display_name;
      //       this.allFilterShow[indivMetricIndex] = false; 
      //       if((metricData.length) - 1 == indivMetricIndex) {
      //               resolve();
      //       }
      //     }
      //   });
      // });
      this.peopleService.getCustomerMetrics(queryParams).subscribe((metricData: any) => {
        let flag=true;
        for (var indivMetricIndex = 0; indivMetricIndex < metricData.length; indivMetricIndex++) {
          this.allMetricsChartData[indivMetricIndex] = metricData[indivMetricIndex];
          this.allFilterShow[indivMetricIndex] = false;
          if ((metricData.length) - 1 == indivMetricIndex) {
            flag=false;
            resolve();
          }
        }
        if(flag){
         reject(); 
        }
      });
    });
  }

  constructCharts() {
    for (var i = 0; i < this.allMetricsChartData.length; i++) {
      if (this.allMetricsChartData[i]['pie_chart_value']) {
        this.dataToPieChart('', 'over_all', i);
      }
      else {
        this.dataToLineChart('', 'over_all', i);
      }
    }
  }

  dataToLineChart(selected: any = '', filterValue: any = 'over_all', chartIndex: number = 0) {
    this.filterTitle[chartIndex] = filterValue;
    let seriesData: any = [];
    if (filterValue == 'over_all') {
      if (selected == '') {
        this.allMetricsDropdown[chartIndex] = [];
        seriesData = this.allMetricsChartData[chartIndex].line_chart_value;
      }
      else {

      }
    }
    else {
      if (selected == '') { }
      else { }

    }
    splineChartOptions.yAxis = {
      title: {
        text: ""
      }
    }
    splineChartOptions.xAxis = {
      // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      //   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      categories: seriesData.categories
    }
    splineChartOptions.colors= ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"];
    this.allMetricSplineCharts[chartIndex] = new Chart(splineChartOptions)
    this.allMetricSplineCharts[chartIndex].addSeries(seriesData, true, true)
  }

  filterChart(checkedValue: any, chartIndex: any) {

  }

  dataToPieChart(selected: any = '', filterValue: any = 'over_all', chartIndex: number = 0) {
    this.filterTitle[chartIndex] = filterValue;
    // let total_resource, no_of_resource;
    let seriesData: any;
    if (filterValue == 'over_all') {
      if (selected == '') {
        this.allMetricsDropdown[chartIndex] = [];
        seriesData = this.allMetricsChartData[chartIndex].pie_chart_value;
      }
    }
    pieChartOptions.colors= ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"];
    this.allMetricPieCharts[chartIndex] = new Chart(pieChartOptions)
    // for(var i = 0; i <= seriesData.length; i++) {
    this.allMetricPieCharts[chartIndex].addSeries(seriesData, true, true)
    // }
  }

  navigateTo(link: string) {
    this.router.navigate([link], { queryParams: this.queryParams })
  }

}
