<div class="container p-0">
    <div class="row">
        <div class="col-md-12 mb-3">
            <div class="card shadow border-bottom border-radius-15">
                <div class="px-3 py-3 d-flex align-items-center justify-content-between border-bottom ">
                    <div>
                        <h6 class="d-flex align-items-end mb-0">
                            <mat-icon class="mr-2">filter_list</mat-icon> {{staticText?.balance_scorecard?.delivery_title}}
                        </h6>
                    </div>

                    <div (click)="navigateTo('balancedscorecard')">
                        <h6 class="d-flex align-items-end back mb-0 cursor-pointer">
                            <span class="font-15"><i class="fa fa-chevron-left back pb-1" aria-hidden="true"></i> {{staticText?.common?.go_back}}</span>
                        </h6>
                    </div>
                </div>
                <div class="p-3" *ngIf="sparkLineData.length !== 0">
                    <span class="badge bg-success badgeTitle">
                        <h5 class="mb-0 fs-14"> {{staticText?.balance_scorecard?.delivery_title}} ({{sparkLineData?.length}})</h5>
                    </span>
                </div>
                <div class="table-style px-3" *ngIf="sparkLineData.length !== 0">
                    <table id="table-sparkline" class="table fs-12">
                        <thead>
                            <tr>
                                <th class="title-1">{{staticText?.balance_scorecard?.dashboard?.metrics}}</th>
                                <th class="title-2">{{staticText?.balance_scorecard?.dashboard?.unit}}</th>
                                <th class="title-3">{{staticText?.balance_scorecard?.dashboard?.trend}}</th>
                                <th class="title-4">{{staticText?.balance_scorecard?.dashboard?.change}}</th>
                            </tr>
                        </thead>
                        <tbody id="tbody-sparkline">
                            <ng-container *ngFor="let metric of sparkLineData">
                                <tr>
                                    <td><span class="fw-bold">{{metric.name}}</span></td>
                                    <!--    <td class="units">{{metric.unit}}</td> -->
                                    <td *ngIf="metric.unit == 'Days'"> <img class="img-align units-icons"
                                            src="./assets/images/calendar-new.svg"></td>
                                    <td *ngIf="metric.unit == 'Number'"> <img class="img-align units-icons"
                                            src="./assets/images/statistics-new.svg"></td>
                                    <td *ngIf="metric.unit == 'Percentage'"> <img class="img-align units-icons"
                                            src="./assets/images/discount-new.svg"></td>
                                    <td>
                                        <div [chart]="metric.areaChart"></div>
                                    </td>
                                    <td class="trend-value text-center" *ngIf="metric.trend.trend == 'Negative'"> 
                                        <span class="d-block spanRed">{{metric.trend_value}}</span><span class="px-2 d-block">{{metric.achieved_value}}%</span>
                                    </td>
                                    <td class="trend-value text-center" *ngIf="metric.trend.trend == 'Positive'">
                                         <span class="d-block spanGreen">{{metric.trend_value}}</span><span class="px-2 d-block">{{metric.achieved_value}}%</span>
                                    </td>
                                    <td class="trend-value text-center" *ngIf="metric.trend.trend == 'Neutral'"> 
                                        <span class="d-block spanNeutral">{{metric.trend_value}}</span><span class="px-2 d-block">{{metric.achieved_value}}%</span>
                                    </td>
                                </tr>
                            </ng-container>

                        </tbody>
                    </table>
                </div>
                <div class="card-body" *ngIf="sparkLineData.length == 0">
                    <div class="row">
                        <div class="col-12 mx-auto">
                            <img src="./assets/images/no-record.png" class="no-records" height="100%"
                                width="100%">
                        </div>
                        <div class="col-8 mx-auto">
                            <p class="text-center fw-bold text-muted">
                                {{staticText.common.no_records_found}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>