import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { ChartsService } from '../charts.service';
import { splineChartOptions } from 'src/app/shared/helpers/splineChartOptions';
import { NgxSpinnerService } from 'ngx-spinner';
declare var require: any;

import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let threeD = require('highcharts/highcharts-3d')
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class FinanceComponent implements OnInit {

  allMetricsDropdown:any[] = [];
  filterTitle: any[] = [];
  allMetricsChartData:any[] = [];
  allMetricChartFilters:any[] = [];
  allFilterShow:boolean[] = [];
  allMetricSplineCharts:Chart[] = [];
  allMetricHistogramCharts:Chart[] =[];
  queryParams:any;
  staticText: any = (textConfiguration as any).default;
  arrow: boolean[] = new Array().fill(false);
  constructor(private router: Router,private peopleService: ChartsService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) { 
    this.activatedRoute.queryParams.subscribe((params:any) => { 
      this.queryParams = params;
      let serviceParameter = new URLSearchParams(params).toString();
      this.spinner.show();
      this.getQuadrantMetrics(serviceParameter)
    });
  }

  ngOnInit(): void {
    // this.getQuadrantMetrics().then((res:any) => {
    //   this.constructCharts();
    // });
    // Highcharts.setOptions({
    //   colors: ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"]
    // });
  }

  getQuadrantMetrics(queryParams:any) {
    //return new Promise<void>((resolve, reject) => {  
      // this.peopleService.getQuadrantMetricList(306, 2).subscribe((quadrantMetrics:any) => { 
      //   console.log(quadrantMetrics);
      //   let allMetricApis:any = [];
      //   for(var metricIndex=0; metricIndex< quadrantMetrics.length; metricIndex++) {
      //     allMetricApis.push(this.peopleService.getMetricData(306, quadrantMetrics[metricIndex].id))
      //   }
      //   forkJoin(allMetricApis).subscribe((metricData:any) => {  
      //     // for(var indivMetricIndex =0; indivMetricIndex < metricData.length; indivMetricIndex++) {
      //     //   this.allMetricChartFilters[indivMetricIndex] = Object.keys(metricData[indivMetricIndex])
      //     //   this.allMetricsChartData[indivMetricIndex] = metricData[indivMetricIndex];
      //     //   this.allMetricsChartData[indivMetricIndex]["name"] = quadrantMetrics[indivMetricIndex].display_name;
      //     //   this.allFilterShow[indivMetricIndex] = false; 
      //     //   if((metricData.length) - 1 == indivMetricIndex) {
      //     //           resolve();
      //     //   }
      //     // }
      //   });
      // });
      this.peopleService.getFinanceMetrics(queryParams).subscribe((metricData:any) => {
        for(var indivMetricIndex =0; indivMetricIndex < metricData.length; indivMetricIndex++) { 
          this.allMetricChartFilters[indivMetricIndex] = metricData[indivMetricIndex]["sub_chart"] ? Object.keys(metricData[indivMetricIndex]["sub_chart"]) : [];
          this.allMetricsChartData[indivMetricIndex] = metricData[indivMetricIndex];
          this.allFilterShow[indivMetricIndex] = false; 
          if((metricData.length) - 1 == indivMetricIndex) {
            //resolve();            
            this.constructCharts();
          }
        }
        this.spinner.hide();
      },err=>this.spinner.hide());

    //});
  }

  constructCharts() {
    for(var i = 0; i < this.allMetricsChartData.length; i++) {
      if(this.allMetricsChartData[i].name =="Unit Resource Cost") {
        this.dataToHistogram('','over_all',i);  
      } 
      else {
        this.dataToLineChart('','over_all',i);
      }
    }
  }

  filterChart(checkedValue: any, chartIndex:any) { 
    this.dataToHistogram("", checkedValue, chartIndex);
  }

  dataToLineChart(selected: any = '', filterValue: any = 'over_all', chartIndex:number = 0) { 
    this.filterTitle[chartIndex] = filterValue;
    let seriesData: any = [];
    seriesData = this.allMetricsChartData[chartIndex].chart_value;
    // if (filterValue == 'over_all') {
    //   if (selected == '') {
    //     seriesData = this.allMetricsChartData[chartIndex].chart_value;
    //   }
    //   else {
    //     seriesData = this.allMetricsChartData[chartIndex].chart_value.line_chart.filter((item: any) => item.name.includes(selected.target.value))
    //   }
    // }
    // else {
    //   if (selected == '') {
    //     if(this.allMetricsChartData[chartIndex].line_chart) {
    //       seriesData = this.allMetricsChartData[chartIndex].line_chart;
    //     }
    //     else {
    //       seriesData = this.allMetricsChartData[chartIndex][this.allMetricsDropdown[chartIndex][0]].line_chart;//this.allMetricsDropdown[chartIndex][0] should be change to all once it's implemented
    //     }
       
    //   }
    //   else {
    //     if(this.allMetricsChartData[chartIndex][filterValue].line_chart) { 
    //       seriesData = this.allMetricsChartData[chartIndex].line_chart.filter((item: any) => item.name.includes(selected.target.value));
    //     }
    //     else {
    //       seriesData = this.allMetricsChartData[chartIndex][selected.target.value].line_chart;
    //     }
        
    //   }
    // }
    splineChartOptions.yAxis = {
      title: {
        text: ""
      }
    }
    splineChartOptions.xAxis = {
      // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      //   "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      categories: seriesData.categories
    },
    splineChartOptions.colors= ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"];
    this.allMetricSplineCharts[chartIndex] = new Chart(splineChartOptions)
    this.allMetricSplineCharts[chartIndex].addSeries(seriesData, true, true)
    // for(var i = 0; i <= seriesData.length; i++) {
    //   this.allMetricSplineCharts[chartIndex].addSeries(seriesData[i], true, true)
    // }
  }

  dataToHistogram(selected: any = '', filterValue: any = 'location', chartIndex:number = 0) {
    let seriesData: any = [];
    this.filterTitle[chartIndex] = filterValue;
    let histogramOptions:any = {
      chart: {
        zoomType: 'xy',
        options3d: {
          enabled: true,
          alpha: 15,
          beta: 15,
          
       }
      },
      title: {
          text: ''
      },
      credits: {
          enabled: false
      },
      legend: {
          enabled: true
      },
      yAxis: [{
        title: {
          text: ""
        },
      }, {
        title: {
          text: ""
        },
        opposite: true,
      }]
    }; 
    // seriesData = this.allMetricsChartData[chartIndex]["sub_chart"][filterValue];
    if (filterValue == 'over_all') {
      if (selected == '') {
        this.allMetricsDropdown[chartIndex] = [];
        seriesData = this.allMetricsChartData[chartIndex]["sub_chart"][filterValue];
        seriesData[0]['yAxis'] = 0;
        seriesData[1]['yAxis'] = 1;
      }
      else {

      }
    }  
    else {
      if (selected == '' || selected.target.value == '') {
        this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].line_chart).map(i => this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].line_chart[i]['name'])
        // this.allMetricsChartData[chartIndex][filterValue].column_chart[0]['yAxis'] = 0;
        // this.allMetricsChartData[chartIndex][filterValue].line_chart[0]['yAxis'] = 1;
        seriesData = [this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].line_chart[0],this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].column_chart[0] ];
        seriesData[0]['yAxis'] = 1;
        seriesData[1]['yAxis'] = 0;
      }
      else {
        this.allMetricsDropdown[chartIndex] = Object.keys(this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].line_chart).map(i => this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].line_chart[i]['name'])
        seriesData = [...this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].line_chart.filter((item: any) => item.name.includes(selected.target.value)), ...this.allMetricsChartData[chartIndex]["sub_chart"][filterValue].column_chart.filter((item: any) => item.name.includes(selected.target.value))]
        seriesData[0]['yAxis'] = 1;
        seriesData[1]['yAxis'] = 0;
      }
    }
    histogramOptions.series = seriesData;
    histogramOptions.colors= ["#07b797", "#1a85d6", "#738199", "#f8ae43", "#dd2c54", "#ab266b", "#003770"];
    this.allMetricHistogramCharts[chartIndex] = new Chart(histogramOptions)
    // this.allMetricHistogramCharts[chartIndex].addSeries(seriesData, true, true)


  }

  navigateTo(link: string) {
    this.router.navigate([link], { queryParams: this.queryParams })
  }
}
