import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ChartsService } from "../charts.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Chart } from "angular-highcharts";
import * as Highcharts from "highcharts";
import highchartsTreemap from "highcharts/modules/treemap";
import { LodashService } from "src/app/shared/services/lodash.service";
import { ProjectService } from "src/app/shared/services/project.service";
highchartsTreemap(Highcharts);
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { DatePipe } from "@angular/common";
import { FormValidatorService } from "src/app/shared/services/form-validator/form-validator.service";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import { MessageService } from "src/app/shared/services/message.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { MatDialog } from "@angular/material/dialog";
import { PortfolioDialogComponent } from "../portfolio-dialog/portfolio-dialog.component";
import { WhatIfComponent } from "../../kepler/what-if/what-if.component";
export const MY_FORMATS = {
  parse: { dateInput: "YYYY" },
  display: { dateInput: "YYYY" },
};

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }, DatePipe],
  encapsulation: ViewEncapsulation.Emulated,
})
export class IndexComponent implements OnInit, OnDestroy {
  upcomingReleases: any = [];
  recentReleases: any = [];
  currentYear: any;
  tips = true;
  showIdea = false;
  showComments = false;
  projectList: any = ([] = []);
  portFolioFilterOptions: Observable<any[]> | any;
  projectFilterOptions: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  subPortfilteredOptions: Observable<any[]> | any;
  filterForm = new FormGroup({});
  option: any;
  OpportunityFieldList: any;
  portFolioList: any[] = [];
  portFolioId: any;
  subPortId: any;
  queryParams: Object = {};
  //programId: any;
  selectedProjectId: any;
  ColorRandom: any;
  treeMapChart1: any;
  treeMapChart2: any;
  filterBreadCrumb: any[] = [];
  scrollpath: boolean = false;
  clearcarousel: object | any;
  vendorList: any;
  filterGDP: any;
  gdpId: any;
  year: any;
  okrHeatMapData: any[] = [];
  // riskHeatMapData: any[] = [];
  riskTableData: any[] = [];
  date = new Date();
  chosenYearDate = new Date();
  staticText: any = (textConfiguration as any).default;
  dateYear: any;
  section1: boolean = true;
  section2: boolean = false;
  section3: boolean = false;
  section4: boolean = false;
  section5: boolean = false;
  section6: boolean = false;
  cards: any;
  okrDetails: any = [];
  riskDetails: any = [];

  keyActionAreas: any = [];
  subscription: any;
  filterObj: any;
  portfolioId: any = null;
  programId: any = null;
  subportfolioId: any = null;
  vendorId: any = null;

  constructor(
    private chartService: ChartsService,
    private datepipe: DatePipe,
    private ScorecardService: ScorecardService,

    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private _formValidator: FormValidatorService,
    private LodashService: LodashService,
    private messageService: MessageService,
    private filterService: FilterService,
    private dialog: MatDialog
  ) {
    this.route.queryParams.subscribe((params: any) => {
      this.initForm();
      this.queryParams = params;
      this.filterForm.patchValue({
        subPortfolio: params.sub_portfolio,
        //programname: params.program,
        projectname: params.project,
        gdp: params.gdp,
      });
      this.year = params.year;
      let serviceParameter = new URLSearchParams(params).toString();
      //  this.getRightPane(serviceParameter);
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url === "/balancedscorecard/dashboard" ||
          event.url === "/balancedscorecard"
        ) {
          this.scrollpath = true;
        } else {
          this.scrollpath = false;
        }
      }
    });
    this.ScorecardService.cardDetails.subscribe((cards) => {
      this.cards = cards;
    });
  }

  ngOnInit(): void {
    this.messageService.setGnews("Balanced Scorecard");

    this.ScorecardService.okrDetails$.subscribe((newData: any) => {
      this.okrDetails = newData;
      if (this.okrDetails?.length > 0) {
        this.okrDetails?.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });
      }
    });

    this.getRiskDetails();

    Highcharts.setOptions({
      colors: [
        "#07b797",
        "#1a85d6",
        "#738199",
        "#f8ae43",
        "#dd2c54",
        "#ab266b",
        "#003770",
      ],
    });
    this.currentYear = new Date().getFullYear();
    this.getPortfolio();
    this.getDeliveryProjectList();
    //this.getProgram();
    this.getGDP();
    this.subPortfilteredOptions = [];

    this.filterForm.patchValue({
      subPortfolio: this.filterForm.get("subPortfolio")?.value,
    });
    // this.setHeatMapChart()
    // this.getHeatMapData();
    this.getReleasesInfo();
    this.subscription = this.filterService.filterSubject.subscribe(
      (res: any) => {
        this.filterObj = res;
        this.portfolioId = "";
        this.subportfolioId = "";
        this.programId = "";
        this.vendorId = "";
        Object.keys(this.filterObj).forEach((element: any) => {
          if (this.filterObj[element].array.length > 0) {
            if (this.filterObj[element].array[0].level == "portfolio") {
              this.portfolioId = this.filterObj[element].array[0].id;
            } else if (
              this.filterObj[element].array[0].level == "sub_portfolio"
            ) {
              this.subportfolioId = this.filterObj[element].array[0].id;
            } else if (this.filterObj[element].array[0].level == "program") {
              this.programId = this.filterObj[element].array[0].id;
            } else if (element == "vendor") {
              this.vendorId = this.filterObj[element].array[0].id;
            }
          }
        });
        this.getFilteredReleases();
        this.getFilteredRiskDetails();
      }
    );
  }

  getFilteredRiskDetails() {
    this.ScorecardService.getFilteredRiskDetails(
      this.portfolioId,
      this.subportfolioId,
      this.programId,
      this.vendorId
    ).subscribe((resp: any) => {
      this.riskDetails = resp;
      this.riskDetails.forEach((risk: any) => {
        risk["color"] = this.getGMapRandomColor();
        risk.portfolio.forEach((portfolio: any) => {
          portfolio["color"] = this.getGMapRandomColor();
        });
      });
    });
  }

  getRiskDetails() {
    this.ScorecardService.getRiskDetails().subscribe((resp: any) => {
      this.riskDetails = resp;
      this.riskDetails.forEach((risk: any) => {
        risk["color"] = this.getGMapRandomColor();
        risk.portfolio.forEach((portfolio: any) => {
          portfolio["color"] = this.getGMapRandomColor();
        });
      });
    });
  }

  getFilteredReleases() {
    this.ScorecardService.getFilteredUpcomingReleases(
      this.portfolioId,
      this.subportfolioId,
      this.programId,
      this.vendorId
    ).subscribe((resp: any) => {
      this.upcomingReleases = resp;
      this.upcomingReleases?.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
      });
    });

    this.ScorecardService.getFilteredRecentReleases(
      this.portfolioId,
      this.subportfolioId,
      this.programId,
      this.vendorId
    ).subscribe((resp: any) => {
      this.recentReleases = resp;
      this.recentReleases?.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUpcomingReleases() {
    this.ScorecardService.getUpcomingReleases().subscribe((resp: any) => {
      this.upcomingReleases = resp;
      this.upcomingReleases?.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
      });
    });
  }

  getRecentReleases() {
    this.ScorecardService.getRecentReleases().subscribe((resp: any) => {
      this.recentReleases = resp;
      this.recentReleases?.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
      });
    });
  }

  getReleasesInfo() {
    this.getUpcomingReleases();
    this.getRecentReleases();
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 30%, 75%, 100%)";
  }

  private initForm() {
    this.filterForm = this.fb.group({
      portfolio: [""],
      subPortfolio: ["", [this._formValidator.autocompleteObjectValidator()]],
      projectname: ["", [this._formValidator.autocompleteObjectValidator()]],
      //programname: ['',[this._formValidator.autocompleteObjectValidator()]],
      gdp: ["", [this._formValidator.autocompleteObjectValidator()]],
      year: [null],
    });
    this.filterForm.patchValue({
      portfolio: {
        id: 0,
        name: "all of it",
        display_name: "All of IT",
      },
    });
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : "";
  }

  addComments() {
    this.showComments = !this.showComments;
  }

  getRightPane(queryParams: any) {
    this.chartService.getRightPane(queryParams).subscribe((res: any) => {
      this.okrHeatMapData = res.okr.map((heatMapData: any, index: any) => {
        return { ...heatMapData, color: this.getRandomcolors(index) };
      });
      this.riskTableData = res?.risk;
      this.keyActionAreas = res?.action;
      this.setHeatMapChart();
    });
  }

  getHeatMapData() {
    this.chartService.getRightPaneHeatMapData().subscribe((res: any) => {
      this.okrHeatMapData = res.okr.map((heatMapData: any, index: any) => {
        return { ...heatMapData, color: this.getRandomcolors(index) };
      });
      // this.riskHeatMapData = res.risk.map((heatMapData: any, index: any) => { return { ...heatMapData, color: this.getRandomcolors(index) } });
      this.setHeatMapChart();
    });
  }

  getRandomcolors(mappedIndex: any) {
    const colors: any[] = [
      "#ab266b",
      "#f8ae43",
      "#738199",
      "#dd2c54",
      "#1a85d6",
      "#07b797",
      "#003770",
    ];
    // let colorIndex = Math.floor(Math.random() * colors.length);
    const colorIndex = mappedIndex % colors.length;
    return colors[colorIndex];
  }

  getPortfolio() {
    this.chartService.getPortfolioList("new_opportunity").subscribe(
      (res: any) => {
        this.OpportunityFieldList = res.records;
        this.OpportunityFieldList.map((i: any) => {
          if (i.field_name == "opportunity_portfolio") {
            this.portFolioList = i.field_values;
            this.portFolioList.sort((a: any, b: any) => a.id - b.id);
            this.LodashService.orderBy(this.portFolioList, "id", "");
            this.portFolioFilterOptions = this.filterForm
              .get("portfolio")!
              .valueChanges.pipe(
                startWith(""),
                map((value: any) =>
                  typeof value === "string" ? value : value?.display_name
                ),
                map((display_name: any) =>
                  display_name
                    ? this.PortFolioFilter(display_name)
                    : this.portFolioList?.slice()
                )
              );
          }
        });
      },
      (error) => {
        // this.toastr.error('Opportunity fields get failed', 'Error');
        throw error;
      }
    );
  }

  private PortFolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    this.option = value;
    return this.portFolioList.filter((i: any) =>
      i.display_name.toLowerCase().includes(filterValue)
    );
  }

  displayPortfolio(data: any) {
    return data && data.display_name ? data.display_name : "";
  }

  selectedPortfolioValues(id: any) {
    this.portFolioId = id;
    this.subPortId = "";
    this.filterForm.patchValue({
      subPortfolio: "",
    });
    let selectedPortfolio = this.portFolioList.find((e: any) => e.id === id);
    this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;

    if (this.portFolioId === "") {
      this.subPortfilteredOptions = [];
      this.filterForm.patchValue({
        subPortfolio: this.filterForm.get("subPortfolio")?.value,
      });
    }
    this.queryParams = { portfolio: this.portFolioId };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  selectedSubPortfolio(id: any) {
    this.subPortId = id;
    this.queryParams = { sub_portfolio: this.subPortId };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: "merge",
    });
  }
  // selectedProgram(id: any) {
  //   this.programId = id;
  //   this.queryParams = { "program": this.programId }
  //   this.router.navigate(
  //     [],
  //     {
  //       relativeTo: this.route,
  //       queryParams: this.queryParams,
  //       queryParamsHandling: 'merge',
  //     });
  // }
  selectedGDP(id: any) {
    this.gdpId = id;
    this.queryParams = { gdp: this.gdpId };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: "merge",
    });
  }

  chosenYearHandler(
    normalizedYear: any,
    datepicker: MatDatepicker<any>,
    yr: any
  ) {
    const ctrlValue = this.date;
    let date = this.datepipe.transform(normalizedYear, "YYYY");
    this.filterForm.controls["year"].setValue(date);
    this.filterForm.patchValue({
      year: date,
    });
    this.dateYear = "" + date;
    setTimeout(() => {
      yr.value = date;
    }, 200);
    datepicker.close();

    this.queryParams = { year: this.dateYear };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: "merge",
    });
  }

  onSelectMonthYear(e: any) {
    //console.log('e year', e);
  }

  SelectedProject(id: any) {
    this.selectedProjectId = id;
    this.queryParams = { project: this.selectedProjectId };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: "merge",
    });
    this.getprojectdetails(id);
  }

  getprojectdetails(id: any) {
    this.projectService
      .getprojectdetailsById(id)
      .subscribe((projectDetails: any) => {
        // this.filterForm.patchValue({
        //   portfolio: projectDetails.opportunity.portfolio_details,
        //   subPortfolio: projectDetails.opportunity.sub_portfolio_details,
        //   programname: projectDetails.opportunity.program_details
        // })
        if (projectDetails.opportunity.portfolio_details?.[0]?.display_name)
          this.filterBreadCrumbMethod({
            id: 1,
            name: projectDetails.opportunity.portfolio_details?.[0]
              ?.display_name,
          });
        if (projectDetails.opportunity.portfolio_details?.[1]?.display_name)
          this.filterBreadCrumbMethod({
            id: 2,
            name: projectDetails.opportunity.portfolio_details?.[1]
              ?.display_name,
          });
        // if (projectDetails.opportunity.program_details.display_name)
        //   this.filterBreadCrumbMethod({ id: 3, name: projectDetails.opportunity.program_details.display_name });
      });
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    this.option = value;
    return this.subPortfilteredOptions.filter((i: any) =>
      i.project_name.toLowerCase().includes(filterValue)
    );
  }

  displaySubportFolio = (data: any) => {
    data = this.subPortfilteredOptions?.find((o: any) => o.id == data);
    return data && data.display_name ? data.display_name : "";
  };

  getDeliveryProjectList() {
    this.chartService.projectList().subscribe((response: any) => {
      this.projectList = response;
      this.filterForm.patchValue({
        projectname: this.filterForm.get("projectname")?.value,
      });
      this.projectFilterOptions = this.filterForm
        .get("projectname")!
        .valueChanges.pipe(
          startWith(""),
          map((value: any) =>
            typeof value === "string" ? value : value?.project_name
          ),
          map((project_name: any) =>
            project_name
              ? this.ProjectFilter(project_name)
              : this.projectList?.slice()
          )
        );
    });
  }

  private ProjectFilter(value: string): any {
    const filterValue = value.toLowerCase();
    this.option = value;
    return this.projectList.filter((i: any) =>
      i.project_name.toLowerCase().includes(filterValue)
    );
  }

  displayProject = (data: any) => {
    data = this.projectList?.find((o: any) => o.id == data);
    return data && data.project_name ? data.project_name : "";
  };

  getGDP() {
    this.chartService.getVendorList().subscribe((res: any) => {
      if (res) {
        this.vendorList = res.records[0].field_values;
        this.filterForm.patchValue({
          gdp: this.filterForm.get("gdp")?.value,
        });
        this.filterGDP = this.filterForm.get("gdp")!.valueChanges.pipe(
          startWith(""),
          map((value: any) =>
            typeof value === "string" ? value : value?.name
          ),
          map((name: any) =>
            name ? this._filterGDP(name) : this.vendorList?.slice()
          )
        );
      }
    });
  }

  private _filterGDP(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.vendorList.filter((tag: any) =>
      tag?.name?.toLowerCase().includes(filterValue)
    );
  }

  displayFnGDP = (data: any) => {
    data = this.vendorList?.find((o: any) => o.id == data);
    return data && data.name ? data.name : "";
  };
  setHeatMapChart() {
    let call = this;
    let chartOptions2: any = {
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          type: "treemap",
          levels: [
            {
              level: 1,
              dataLabels: {
                enabled: true,
                useHTML: true,
                style: {
                  // fontSize: '16px',
                  // fontWeight: "normal",
                  // textOutline: "0",
                  // textOverflow: "none",
                  //padding:'5px',
                  //width:"40px"
                },
              },
            },
          ],
          layoutAlgorithm: "squarified",
          data: [...this.okrHeatMapData],
        },
      ],
    };
    chartOptions2.series[0].levels[0].dataLabels.formatter = function () {
      return "<p class='pl-1'>" + this.point.name + "</p>";
    };
    //this.treeMapChart1 = new Chart(chartOptions1)
    chartOptions2.colors = [
      "#07b797",
      "#1a85d6",
      "#738199",
      "#f8ae43",
      "#dd2c54",
      "#ab266b",
      "#003770",
    ];
    this.treeMapChart1 = new Chart(chartOptions2);
    // this.treeMapChart2 = new Chart(chartOptions2)
  }

  filterBreadCrumbMethod(name: any) {
    this.pushLastValue(this.filterBreadCrumb, name);
    this.filterBreadCrumb.sort((a: any, b: any) => a.id - b.id);
  }

  pushLastValue(arr: any, obj: any) {
    var index: any;
    index = arr.findIndex((e: any) => e.id === obj.id);
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
    return arr;
  }

  resetFilter() {
    this.filterForm.reset();
    this.dateYear = "";
    this.filterBreadCrumb = [];
    this.queryParams = {};
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
    });
    this.filterForm.patchValue({
      portfolio: {
        id: 0,
        name: "all of it",
        display_name: "All of IT",
      },
    });
  }

  openPortfolio(portfolio: any) {
    const dialog = this.dialog.open(PortfolioDialogComponent, {
      width: "auto",
      minWidth: "40vw",
      maxWidth: "50vw",
      height: "auto",
      data: {
        portfolioInfo: portfolio,
      },
    });
    dialog.afterClosed().subscribe((data) => {});
  }

  openWhatIfDialog() {
    this.dialog.open(WhatIfComponent, {
      width: "auto",
      minWidth: "60vw",
      height: "auto",
    });
  }
}
