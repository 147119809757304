import { Options } from 'highcharts';

export const pieChartOptions: Options = {
    chart: {
        type: 'pie',
        height:300,
        plotShadow: false,
        options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
         }
    },
    title: {
        text: undefined
    },

    credits: {
        enabled: false
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
                enabled: false,
                padding: 0,
                verticalAlign: 'middle'
            },
            showInLegend: true,
            size: 200
        }
    },
    legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
    }
}