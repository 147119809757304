import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerComponent } from './customer/customer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { FinanceComponent } from './finance/finance.component';
import { IndexComponent } from './index/index.component';
import { PeopleComponent } from './people/people.component';

const routes: Routes = [
  {
    path: '',
    data: { breadcrumb: 'Kaleidoscope' },
    component: IndexComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'customer',
        component: CustomerComponent
      },
      {
        path: 'finance',
        component: FinanceComponent
      },
      {
        path: 'delivery',
        component: DeliveryComponent
      },
      {
        path: 'people',
        component: PeopleComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BalancescoreRoutingModule { }
