import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalancescoreRoutingModule } from './balancescore-routing.module';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { FinanceComponent } from './finance/finance.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { PeopleComponent } from './people/people.component';
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortfolioDialogComponent } from './portfolio-dialog/portfolio-dialog.component';

@NgModule({
  declarations: [
    IndexComponent,
    DashboardComponent,
    CustomerComponent,
    FinanceComponent,
    DeliveryComponent,
    PeopleComponent,
    PortfolioDialogComponent
  ],
  imports: [
    CommonModule,
    BalancescoreRoutingModule,
    FormsModule, ReactiveFormsModule,
    SharedModule,
    MaterialmodulesModule,
    ChartModule,
    HighchartsChartModule,
    MatIconModule,
    NgbModule,
    DragDropModule,
    NgCircleProgressModule.forRoot({

      "radius": 40,
      "space": -15,
      "outerStrokeGradient": true,
      "outerStrokeWidth": 10,
      "backgroundPadding": -10,
      "outerStrokeColor": "#07B797",
      "outerStrokeGradientStopColor": "#07B797",
      "innerStrokeColor": "#e7e8ea",
      "innerStrokeWidth": 15,
      "title": "",
      "animateTitle": false,
      "animationDuration": 1000,
      "showUnits": false,
      "showBackground": false,
      "clockwise": false,
      "startFromZero": false,
      "lazy": false,
      "maxPercent": 100,
    })
  ]
})
export class BalancescoreModule { }
