import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { GetRandomColorPipe } from 'src/app/shared/pipes/get-random-color.pipe'
import { CurrencyPipe } from '@angular/common';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { ProjectService } from 'src/app/shared/services/project.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import {ScorecardService} from 'src/app/shared/services/scorecard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [GetRandomColorPipe,CurrencyPipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class DashboardComponent implements OnInit {
  projectId: any = null;
  queryParams:any;
  filterObj:any;
  filterString:any='';
  breadcrumb: any;
  private unsubscribe$ = new Subject<void>();
  constructor(private router: Router,
    private ScorecardService : ScorecardService,
    private breadCrumService: BreadcrumbService,private projectService: ProjectService, private filterService: FilterService,private activatedRoute: ActivatedRoute, private colorPipe: GetRandomColorPipe,private currencyPipe:CurrencyPipe) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;
      let serviceParameter = new URLSearchParams(params).toString();
    });
    
  }
  ngOnInit(): void {
    this.ScorecardService.changeMessage('nodata');
    this.filterService.filterSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.filterObj = res;
      let breadcrumbType = {label:'All of IT',url: '/balancedscorecard/dashboard'};
      let breadCrumbValue:any;
       Object.keys(this.filterObj).forEach((element: any) => {
          if(this.filterObj[element].array.length > 0){
            if(this.filterObj[element].array[0].level == "portfolio"){
              breadcrumbType = {label:'Portfolio',url: '/balancedscorecard/dashboard'};
              breadCrumbValue = {label:this.filterObj[element].array[0].name,url:''};
            }else if(this.filterObj[element].array[0].level == "sub_portfolio"){
              breadcrumbType = {label:'Sub Portfolio',url: '/balancedscorecard/dashboard'};
              breadCrumbValue = {label:this.filterObj[element].array[0].name,url:''};
            }else if(this.filterObj[element].array[0].level == "program"){
              breadcrumbType = {label:'Program',url: '/balancedscorecard/dashboard'}
              breadCrumbValue = {label:this.filterObj[element].array[0].name,url:''};
            }else {
              breadcrumbType = {label:'OU/GDP',url: '/balancedscorecard/dashboard'}
              breadCrumbValue = {label:this.filterObj[element].array[0].name,url:''};
            };
          }
        });
        
      this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
        this.breadcrumb = bc;
      })
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      
        let breadCrumb = [
          {label:'Kaleidoscope',url: '/balancedscorecard/dashboard'},
          {label:'Balanced Scorecard',url: '/balancedscorecard/dashboard'},
          breadcrumbType
        ];
        if(breadCrumbValue){
          breadCrumb.push(breadCrumbValue);
        }
        this.breadCrumService.setBreadcrumb(breadCrumb);
    });
    
  }
  navigateTo(link: string) {
    this.router.navigate([link], { queryParams: this.queryParams })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
